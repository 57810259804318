<template>
  <!-- eslint-disable vue/multi-word-component-names -->
    <div>
      <h2>这个是默认的首页啊</h2>
      <h1>{{ pageTitle }}</h1>
      <p>{{ pageContent }}</p>
      <hr>
      <ul>
        <li>
          <router-link :to="{ path: '/loufeng/shanghai/jingan/23' }">上海静安区23页</router-link>
        </li>
        <li>
          <router-link
  :to="{ name: 'lfsc', params: { province: 'sichuan', city: 'chengdu', page: 23 } }"
>成都23页</router-link>
        </li>
        <li>
          <router-link
  :to="{ name: 'ssc', params: { province: 'shenzhen', city: 'futian', page: 232 } }"
>进入我们的社交</router-link>
        </li>
      </ul>
     

     


    </div>
  </template>
  
  <script>
  /* eslint-disable vue/multi-word-component-names */
  export default {
    data() {
      return {
        pageTitle: 'Sample Page home.vue',
        pageContent: 'This is a sample Vue component.'
      };
    },
    methods: {
      // 可以在这里定义组件的方法
      init(){
        console.log("点击了homve.vue的init方法");
        if(confirm("要跳转吗？")) {
          this.$router.push({ name: 'lfsc', params: { province: 'sichuan', city: 'loufeng', page: 23 } });
          
        }
      }
    }
  };
  </script>
  
  <style>
  /* 可以在这里编写组件的样式 */
  h1 {
    color: #333;
    font-size: 24px;
  }
  
  p {
    color: #666;
    font-size: 16px;
  }
  li{
    margin-top: 17px;
  }
  </style>
  