// router.js

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/HomeView.vue';
import About from '../views/AboutView.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Home, name: 'index'  },
  { path: '/about', component: About, name: 'about'  },
  { path: '/loufeng/:province/:city/:page', component: About, name: 'lfsc'  },
  { path: '/social/:province/:city/:page', component: About, name: 'ssc'  },
];

const router = new VueRouter({
  routes,
  mode:"history"
});

export default router;
