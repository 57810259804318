<template>
    <!-- eslint-disable vue/multi-word-component-names -->
    <div>
      <h1>{{ pageTitle }}</h1>
      <p>{{ pageContent }}</p>
      <hr>
      <h1>{{ $route.params.province }}</h1>
    <h2>{{ $route.params.city }}</h2>
    <p>Page: {{ $route.params.page }}</p>
    <hr>
    <p>
      <a href="/">首页</a>
    </p>
    </div>
  </template>
  
  <script>
  /* eslint-disable vue/multi-word-component-names */
  export default {
    data() {
      return {
        pageTitle: 'Sample Page about.vue',
        pageContent: 'This is a sample Vue component.'
      };
    },
    methods: {
      // 可以在这里定义组件的方法
    }
  };
  </script>
  
  <style>
  /* 可以在这里编写组件的样式 */
  h1 {
    color: #333;
    font-size: 24px;
  }
  
  p {
    color: #666;
    font-size: 16px;
  }
  </style>
  